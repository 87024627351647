<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="700px"
  >
    <v-card
      :loading="loadingGet"
    >
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Pergunta
          <span
            v-if="questionId"
            class="body-2"
          >
            <i>Editando</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-form>
            <v-container class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="typeOfQuestionId"
                  >
                    <v-select
                      v-model="fields.typeOfQuestionId"
                      :items="typesOfQuestions"
                      item-value="id"
                      item-text="description"
                      label="Tipo"
                      hide-details="auto"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="nextQuestionId"
                  >
                    <v-autocomplete
                      v-model="fields.nextQuestionId"
                      item-value="id"
                      item-text="title"
                      label="Próxima Pergunta"
                      hide-details="auto"
                      clearable
                      :items="questions"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="title"
                  >
                    <v-text-field
                      v-model="fields.title"
                      hide-details="auto"
                      label="Título"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="description"
                  >
                    <v-textarea
                      v-model="fields.description"
                      hide-details="auto"
                      label="Descrição"
                      rows="3"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                v-if="fields.typeOfQuestionId"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-switch
                    v-model="fields.required"
                    class="mt-0"
                    label="Obrigatório"
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="isCloseQuestion"
                  cols="12"
                  md="6"
                >
                  <v-switch
                    v-model="fields.multipleChoice"
                    class="mt-0"
                    label="Múltipla Escolha"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="fields.typeOfQuestionId && isOpenQuestion"
              >
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="characterLimit"
                  >
                    <v-text-field
                      v-model="fields.characterLimit"
                      hide-details="auto"
                      label="Limite de Caracteres"
                      v-mask="'#####'"
                      :disabled="fields.numeric"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                 <v-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="mask"
                  >
                    <v-text-field
                      v-model="fields.mask"
                      hide-details="auto"
                      label="Máscara"
                      hint="# - Número <br> A - Letra <br> N - Letra ou número <br> <b>CPF:</b> ###.###.###-## <br>
                        <b>Telefone</b>: (##) # ####-####"
                      persistent-hint
                      :disabled="fields.numeric"
                      :error-messages="errors"
                    >
                      <template v-slot:message="{message, key}">
                        <div v-html="message" :key="key"></div>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="numeric"
                  >
                    <v-switch
                      v-model="fields.numeric"
                      label="Monetário"
                      :error-messages="errors"
                    ></v-switch>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                v-if="fields.typeOfQuestionId && isCloseQuestion"
              >
                <v-col>
                  <dialog-question-items
                    v-model="fields.items"
                    :survey-id="surveyId"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="fields.typeOfQuestionId && isTableQuestion"
              >
                <v-col>
                  <dialog-question-table
                    v-model="fields.tableSchema"
                    :survey-id="surveyId"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import questionsApi from '@/api/questions'
  import typesOfQuestionsApi from '@/api/types-of-questions'

  export default {

    components: {
      DialogQuestionItems: () => import('@/components/dialog/questions/DialogQuestionItems'),
      DialogQuestionTable: () => import('@/components/dialog/questions/DialogQuestionTable'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      surveyId: {
        type: Number,
        default: 0,
      },
      questionId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loadingSave: false,
        loadingGet: false,
        typesOfQuestions: [],
        fields: { },
        isOpenQuestion: false,
        isCloseQuestion: false,
        isTableQuestion: false,
        questions: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {
      this.init()
    },

    watch: {
      'fields.typeOfQuestionId' () {
        if (!this.fields.typeOfQuestionId) {
          return
        }

        const type = this.typesOfQuestions.filter(type => type.id === this.fields.typeOfQuestionId)[0]

        this.isOpenQuestion = type.key === 'OPEN'
        this.isCloseQuestion = type.key === 'CLOSE'
        this.isTableQuestion = type.key === 'TABLE'

        if (this.isCloseQuestion && !this.fields?.id) {
          this.fields.multipleChoice = false
        }
      },
    },

    methods: {
      async init () {
        this.loadTypesOfQuestions()
        this.loadQuestions()

        if (this.questionId) {
          this.loadQuestion()
        } else {
          this.fields = {
            items: [],
            tableSchema: {
              rows: [],
              columns: [],
            },
          }
        }
      },

      async loadTypesOfQuestions () {
        try {
          this.loadingGet = true
          this.typesOfQuestions = []

          const responseTypesOfQuestions = await typesOfQuestionsApi.list()

          this.typesOfQuestions = responseTypesOfQuestions.data.typesOfQuestions
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingGet = false
        }
      },

      async loadQuestions () {
        try {
          this.loadingGet = true

          const response = await questionsApi.list(this.surveyId)

          this.questions = response.data.questions
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingGet = false
        }
      },

      async loadQuestion () {
        try {
          this.loadingGet = true
          this.question = {}

          const responseQuestion = await questionsApi.get(this.surveyId, this.questionId)

          const question = responseQuestion.data.question

          if (question.typeOfQuestion.key === 'TABLE') {
            question.tableSchema = JSON.parse(question.schema)
          }

          this.fields = question
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingGet = false
        }
      },

      async save () {
        this.loadingSave = true
        this.$refs.observer.reset()

        try {
          const question = { ...this.fields }
          question.characterLimit = question.characterLimit || null
          question.multipleChoice = typeof question.multipleChoice !== 'undefined' ? question.multipleChoice : null
          question.numeric = question.numeric || false
          question.mask = question.mask || null
          question.required = typeof question.required !== 'undefined' ? question.required : false
          question.schema = JSON.stringify(question.tableSchema) || null

          delete question.tableSchema

          if (this.questionId) {
            await questionsApi.update(this.surveyId, this.questionId, question)
          } else {
            await questionsApi.insert(this.surveyId, question)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },
    },

  }
</script>

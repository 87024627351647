var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"700px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.loadingGet}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Pergunta "),(_vm.questionId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("Editando")])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"typeOfQuestionId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typesOfQuestions,"item-value":"id","item-text":"description","label":"Tipo","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.typeOfQuestionId),callback:function ($$v) {_vm.$set(_vm.fields, "typeOfQuestionId", $$v)},expression:"fields.typeOfQuestionId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"nextQuestionId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-value":"id","item-text":"title","label":"Próxima Pergunta","hide-details":"auto","clearable":"","items":_vm.questions,"error-messages":errors},model:{value:(_vm.fields.nextQuestionId),callback:function ($$v) {_vm.$set(_vm.fields, "nextQuestionId", $$v)},expression:"fields.nextQuestionId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Título","error-messages":errors},model:{value:(_vm.fields.title),callback:function ($$v) {_vm.$set(_vm.fields, "title", $$v)},expression:"fields.title"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","label":"Descrição","rows":"3","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}])})],1)],1),(_vm.fields.typeOfQuestionId)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Obrigatório","hide-details":""},model:{value:(_vm.fields.required),callback:function ($$v) {_vm.$set(_vm.fields, "required", $$v)},expression:"fields.required"}})],1),(_vm.isCloseQuestion)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Múltipla Escolha","hide-details":""},model:{value:(_vm.fields.multipleChoice),callback:function ($$v) {_vm.$set(_vm.fields, "multipleChoice", $$v)},expression:"fields.multipleChoice"}})],1):_vm._e()],1):_vm._e(),(_vm.fields.typeOfQuestionId && _vm.isOpenQuestion)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"characterLimit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"hide-details":"auto","label":"Limite de Caracteres","disabled":_vm.fields.numeric,"error-messages":errors},model:{value:(_vm.fields.characterLimit),callback:function ($$v) {_vm.$set(_vm.fields, "characterLimit", $$v)},expression:"fields.characterLimit"}})]}}],null,false,2313862929)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"vid":"mask"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Máscara","hint":"# - Número <br> A - Letra <br> N - Letra ou número <br> <b>CPF:</b> ###.###.###-## <br>\n                      <b>Telefone</b>: (##) # ####-####","persistent-hint":"","disabled":_vm.fields.numeric,"error-messages":errors},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
var key = ref.key;
return [_c('div',{key:key,domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.fields.mask),callback:function ($$v) {_vm.$set(_vm.fields, "mask", $$v)},expression:"fields.mask"}})]}}],null,false,2922423160)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":"Monetário","error-messages":errors},model:{value:(_vm.fields.numeric),callback:function ($$v) {_vm.$set(_vm.fields, "numeric", $$v)},expression:"fields.numeric"}})]}}],null,false,3846289749)})],1)],1):_vm._e(),(_vm.fields.typeOfQuestionId && _vm.isCloseQuestion)?_c('v-row',[_c('v-col',[_c('dialog-question-items',{attrs:{"survey-id":_vm.surveyId},model:{value:(_vm.fields.items),callback:function ($$v) {_vm.$set(_vm.fields, "items", $$v)},expression:"fields.items"}})],1)],1):_vm._e(),(_vm.fields.typeOfQuestionId && _vm.isTableQuestion)?_c('v-row',[_c('v-col',[_c('dialog-question-table',{attrs:{"survey-id":_vm.surveyId},model:{value:(_vm.fields.tableSchema),callback:function ($$v) {_vm.$set(_vm.fields, "tableSchema", $$v)},expression:"fields.tableSchema"}})],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }